import {DefaultService} from "@/services/default";


export class OperationService extends DefaultService {
    static resourceURL = 'operation/'

    static orderLines(params = {}, headers = {}) {
        return this.request('order-lines/', 'GET', null, params, headers);
    }

    static searchOrderLine(params = {}, headers = {}) {
        return this.request('search-order-lines/', 'GET', null, params, headers);
    }
}