<template>
  <div class="toast-container p-3 start-50 translate-middle-x" style="top:48px">
    <div v-for="toast of toasts" :id="toast.id" v-toast :class="'toast text-' + toast.color " aria-atomic="true"
         aria-live="assertive" data-bs-delay="3000" role="alert">
      <div :class="'toast-header text-' + toast.color">
        <strong v-if="toast.title" class="me-auto">{{ toast.title }}</strong>
        <small v-if="toast.date" class="text-muted">{{ toast.date }}</small>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="toast" type="button"></button>
      </div>
      <div class="toast-body" v-html="toast.message"></div>
    </div>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
import {ref} from "vue";

const appStore = ref(useAppStore());
const toasts = appStore.value.toasts; // Access toasts from the store
</script>

<style scoped>

</style>