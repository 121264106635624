const OperationList = () => import("@/views/operation/OperationList.vue");
const OperationChange = () => import("@/views/operation/OperationChange.vue");


const operation_routes = [
    {
        path: '',
        name: 'OperationList',
        component: OperationList,
        props: route => ({params: route.query})
    },
    {
        path: ':pk',
        name: 'OperationChange',
        component: OperationChange,
        props: route => ({params: route.query, pk: route.params.pk})
    }
]
export default operation_routes;